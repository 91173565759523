<template>
  <div class="qrcode-create">
    <div class="qrcode-create-content">
      <el-row class="qrcode-create-content-info">
        <el-col :span="6" class="icon">
          <i class="iconfont icon-erweima"></i>
        </el-col>
        <el-col :span="18">
          <p class="info-title">快速分配设备</p>
          <p class="tip">建立设备与部门之间关系</p>
        </el-col>
      </el-row>

      <el-form ref="form" class="qrcode-create-content-form" label-width="80px">

        <el-form-item label="关联部门">
          <div class="item-choose">
            <p class="remark"><span>已选部门<i>(点击标签可删除)</i></span>
              <el-button type="text" @click="bindDeptVisible = true">新增</el-button>
            </p>
            <div>
              <el-tag
                size="small"
                v-for="tag in bindDept.wxId"
                :key="tag"
                @close="handleClose(bindDept.wxId,tag)"
                closable
                :type="tag.type">
                <department-name type="departmentName" :openid="tag.id"></department-name>
                <template v-if="tag.type === 1">（含子部门）</template>
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="关联设备">
          <div class="item-choose">
            <p class="remark"><span>已选设备 <i>(点击标签可删除)</i></span>
              <el-button type="text" @click="loadDevices">新增</el-button>
            </p>
            <div>
              <el-tag
                size="small"
                v-for="tag in devices"
                :key="tag.uuid"
                @close="handleClose(devices,tag)"
                closable>
                {{tag.name}}
              </el-tag>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSubmit">立即绑定</el-button>
        </el-form-item>
      </el-form>
    </div>

    <c-dialog :visible.sync="bindDeviceVisible" :width="'42rem'" :height="'37.5rem'">
      <div slot="title" class="input-title">
        <p class="input-title-text">绑定设备</p>
        <div class="input-title-container">
          <el-button size="mini" type="primary" plain @click="handleBindDevice">关联设备</el-button>
        </div>

        <div class="input-title-filter">
          <p>过滤条件:</p>
          <el-input v-model="bind.name" placeholder="请输入设备名称" @change="inputChange" size="mini"/>
        </div>

      </div>

      <devices ref="devices" :corpid="employee.corpid" :code="employee.code"></devices>
    </c-dialog>

    <c-dialog :visible.sync="bindDeptVisible" :width="'42rem'" :height="'32rem'">
      <div slot="title" class="input-title">
        <p class="input-title-text">绑定部门</p>
        <div class="input-title-container">
          <el-button size="mini" type="primary" plain @click.stop="handDeptBindDevice">关联设备</el-button>
        </div>
      </div>
      <tree-depart :mode="1" ref="treeDept"></tree-depart>
<!--      <el-checkbox :indeterminate="bindDept.indeterminate" v-model="bindDept.checkAll"-->
<!--                   @change="handleCheckAllChange">全选-->
<!--      </el-checkbox>-->
<!--      <div style="margin: 15px 0;"></div>-->
<!--      <el-checkbox-group v-model="bindDept.wxId" @change="handleCheckedWxIdChange">-->
<!--        <el-checkbox v-for="department in departments" :label="department.id" :key="department.id">-->
<!--          <department-name type="departmentName" :openid="department.id"></department-name>-->
<!--        </el-checkbox>-->
<!--      </el-checkbox-group>-->
    </c-dialog>
  </div>
</template>

<script>
  import DrinkList from '@/components/DrinkGroup'
  import Devices from '@/components/Device'
  import CDialog from '@/components/Dialog'
  import {qrCodeCreate} from '@/api/marketing/qrcode'
  import DepartmentName from '@/components/DepartmentName'
  import {mapGetters} from "vuex";

  import TreeDepart from '@/components/TreeDepart/index.vue'

  import {
    workDepartmentList,
    workDepartmentDeviceBatchBind
  } from '@/api/work/department'
  export default {
    name: 'qr-code-create',
    components: {
      DrinkList,
      Devices,
      CDialog,
      DepartmentName,
      TreeDepart
    },
    computed: {
      ...mapGetters(['employee']),
    },
    mounted: function () {
    },
    activated() {
      // workDepartmentList({
      //   corpid: this.employee.corpid,
      //   code: this.employee.code,
      //   start: 0, limit: 100
      // }).then(res => {
      //   this.departments = res.data || []
      // })
    },
    data: function () {
      return {
        bindDeptVisible: false,
        bindDeviceVisible: false,
        departments: [],
        devices: [],
        bind: {
          name: '',
        },
        bindDept: {
          visible: false,
          indeterminate: false,
          checkAll: false,
          wxId: [],
          deviceUuid: ''
        },
      }
    },
    methods: {
      handleCheckAllChange(val) {
        let wxId = []
        this.departments.forEach(dept => {
          wxId.push(dept.id)
        })
        this.bindDept.wxId = val ? wxId : [];
        this.bindDept.isIndeterminate = false;
      },

      handleCheckedWxIdChange(value) {
        let checkedCount = value.length;
        this.bindDept.checkAll = checkedCount === this.departments.length;
        this.bindDept.isIndeterminate = checkedCount > 0 && checkedCount < this.departments.length;
      },
      handDeptBindDevice() {
        this.bindDept.wxId = this.$refs.treeDept.getSelect()
        this.bindDeptVisible = false
      },
      loadDevices() {
        this.bindDeviceVisible = true
        let vue = this
        this.$nextTick(function () {
          this.$refs.devices.loadDevices('', vue.devices)
        })
      },

      handleBindDevice() {
        let devices = this.$refs.devices.getDeviceDetail() || []
        if (devices.length === 0) {
          this.$message.warning("请先选取需要绑定的设备")
          return
        }
        this.devices = devices
        this.bindDeviceVisible = false
      },

      inputChange(val) {
        this.$refs.devices.loadDevices(val, this.devices)
      },


      handleClose(obj, tag) {
        obj.splice(obj.indexOf(tag), 1);
      },

      handleSubmit() {
       if (this.devices.length === 0){
         this.$message.warning('请选取设备')
         return
       }

        if (this.bindDept.wxId.length === 0){
          this.$message.warning('请选取设备')
          return
        }
        let deviceUUID = []
        this.devices.forEach(device => {
          deviceUUID.push(device.uuid)
        })

        workDepartmentDeviceBatchBind({
          code: this.employee.code,
          corpid: this.employee.corpid,
          wxId: this.bindDept.wxId,
          deviceUuid: deviceUUID
        }).then(res => {
            this.$message.success('绑定成功')
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .qrcode-create {
    &-content {
      max-width: 720px;

      margin: 0 auto;

      .tip {
        font-size: 12px;
        color: #999999;
      }

      .pl {
        padding-left: $padding;
      }

      &-info {
        padding: $padding;
        margin-bottom: 32px;
        border-bottom: 1px solid;
        @include set_border_color($--border-color, $--border-color-dark);

        .icon {
          text-align: center;
          padding-top: $padding;

          .iconfont {
            background-color: $-theme-light-1;
            border-radius: 15%;
            padding: $padding-4;
            font-size: 48px;
            color: white;
          }
        }

        .info-title {
          font-size: 18px;
          color: #333333;
          line-height: 1.75;
        }
      }


      &-form {
        padding: 0 $padding;

        .item-choose {
          border: 1px dashed $_theme-light-7;
          border-radius: $border-radius;
          padding: $padding;
        }

        .el-tag {
          margin-right: $padding-8;
        }

        .line {
          text-align: center;
        }

        .long {
          margin-left: $padding-8;
        }

        .remark {
          span, .el-button {
            font-size: 13px;
          }

          span {
            color: #333333;
          }

          i {
            font-size: 12px;
            color: #999999;
            display: inline-block;
            margin-right: $padding;
            margin-left: $padding-4;
          }
        }

      }
    }


  }
</style>
